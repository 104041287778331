<template>
    <b-overlay :show="busySaving">
        <b-card style="min-height: 400px">
            <div v-if="selected_product_details != null">
                <!-- header -->
                <div class="d-flex">
                    <b-button variant="primary" size="sm" pill class="mb-2 mt-1 mr-2" @click="setSelectedProduct(-1)">
                        <i class="mdi mdi-arrow-left mr-1"></i> Back
                    </b-button>
                    <h4 class="mt-2 mb-2">
                        {{ selected_product_details.name }}
                    </h4>
                    <b-button variant="info" size="sm" pill class="ml-auto mb-2 mt-1 mr-2" @click="$emit('editProduct', selected_product_details.id)">
                        <i class="mdi mdi-square-edit-outline mr-1"></i> Edit Details
                    </b-button>
                </div>
                <!-- content -->
                <b-row>
                    <!-- left column (details) -->
                    <b-col cols="8">
                        <div class="mt-3">
                            <h6 class="text-success text-uppercase">
                                {{ selected_product_details.group }}
                            </h6>
                            <h5 class="mb-4">
                                <b>R{{ selected_product_details.price }}</b>
                            </h5>
                            <hr />
                            <div>
                                <h5>
                                    <i class="bx bxs-purchase-tag-alt font-size-16 me-1"></i>
                                    Properties
                                </h5>
                                <!-- tiles of all the properties assigned to the product -->
                                <div class="propertyContainer">
                                    <propertyTile
                                        v-for="item in selected_product_details.properties"
                                        :property="item"
                                        :key="item.id"
                                        @editProperty="handleEditProperty"
                                        class="propertyTile border border-light"
                                    >
                                    </propertyTile>
                                </div>
                            </div>
                        </div>
                    </b-col>

                    <!-- right column (image) -->
                    <b-col cols="4" v-if="this.selected_product_details">
                        <div class="">
                            <div class="">
                                <!-- require('@/assets/images/product/img-1.png') -->

                                <img
                                    v-if="selected_product_details.public_img_src.length > 5"
                                    :src="selected_product_details.public_img_src"
                                    alt
                                    style="height: 400px"
                                    class="img-fluid mx-auto d-block"
                                />
                                <!-- Else just show the default -->
                                <img
                                    v-else
                                    src="#"
                                    alt
                                    style="height: 400px"
                                    class="img-fluid mx-auto d-block"
                                />
                                <!--<img src="/img/img-1.a9b1b2a3.png" alt="" class="img-fluid mx-auto d-block">-->
                            </div>
                        </div>
                        <uploader
                            :options="renderUploadOptions"
                            :autoStart="true"
                            :ref="'attach_upload'"
                            class="uploader-example mt-3"
                            @file-added="onFileAdded"
                            @file-success="onFileSuccess"
                        >
                            <uploader-unsupport></uploader-unsupport>
                            <uploader-drop>
                                <p>Drop files here to upload or</p>
                                <uploader-btn class="btn">Select File</uploader-btn>
                                <!--<uploader-btn :attrs="attrs">select images</uploader-btn>
                <uploader-btn :directory="true">select folder</uploader-btn>-->
                            </uploader-drop>
                            <uploader-list></uploader-list>
                        </uploader>
                    </b-col>
                </b-row>
            </div>

            <propertyModal v-if="show_modal" :property_id="edit_property_id" :product_id="selected_product_id" />

            <b-overlay :show="selected_product_details == null" no-wrap></b-overlay>
        </b-card>
    </b-overlay>
</template>

<script>
import { productComputed, productMethods } from "@/state/helpers";
import propertyTile from "./propertyTile.vue";
import propertyModal from "../properties/propertyModal.vue";

export default {
    components: {
        propertyTile,
        propertyModal,
    },
    data: () => ({
        busySaving: false,
        hasMounted: false,
        show_modal: false,
        edit_property_id: -1,
    }),
    computed: {
        ...productComputed,
        renderUploadOptions() {
            var storage = window.localStorage;
            var AUTH_TOKEN = storage.getItem("x_token");
            var url = process.env.VUE_APP_API_BASEURL + "api/products/uploadimage";
            var options = {
                autoStart: true,
                target: url,
                query: {
                    product_id: this.selected_product_id,
                    //    sop_type_id: this.soptype,
                },
                headers: {
                    Authorization: "Bearer " + AUTH_TOKEN,
                },

                testChunks: false,
                withCredentials: false,
                fileParameterName: "image",
                singleFile: true, //Limit upload to one file
            }; //

            return options;
        },
    },
    methods: {
        ...productMethods,
        //eslint-disable-next-line
        onFileAdded() {
            this.busySaving = true;
        },
        //eslint-disable-next-line
        onFileSuccess(e) {
            this.getProductDetails().then(() => {
                this.busySaving = false;
            });
        },
        isProductLoaded() {
            //* if selected_product_id != selected_product_details.id then load product details
            if (this.selected_product_details == null) {
                // if selected_product_details == null then we don't have to check for the id.
                //  return false to load the deatils
                return false;
            }
            return this.selected_product_details.id == this.selected_product_id;
        },
        handleEditProperty(property_id) {
            // set the id of the property to be edited in the pr
            this.edit_property_id = property_id;
        },
        showPropertyModal() {},
    },

    mounted() {
        if (this.isProductLoaded()) {
            this.hasMounted = true;
        } else {
            this.getProductDetails().then(() => {
                this.hasMounted = true;
            });
        }

        //* load properties if the 'all_properties' array is empty
        this.getProperties();
    },
};
</script>

<style>
.propertyContainer {
    display: flex;
    flex-flow: row wrap;
}
.propertyTile {
    /*background: white;*/
    margin: 0.25rem 0.25rem 0.35rem 0.25rem;
    height: 2.5rem;
    /*width: calc( (100% / 3) - 20px );*/
    width: 12rem;
    padding: 0.5rem;

    /* allow floating top right icon */
    position: relative;
}
</style>
