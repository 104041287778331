<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import productsView from "@/components/product-properties/products/productsView.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Products",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, productsView },
    data() {
        return {
            title: "Products",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter Page",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" />
        <productsView />
    </Layout>
</template>
