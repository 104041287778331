<template>
    <div :class="hasSavedValue" @mouseenter="setHoverState(true)" @mouseleave="setHoverState(false)" :id="tileID">
        <span class="text-muted d-inline-flex">
            <span class="text-capitalize">{{property.name}}</span>
            :
            &nbsp;
            <span v-show="is_editing_property == false" class="text-capitalize">{{property.value}}</span>
            <b-form-input v-show="is_editing_property" v-model="edit_value" size="sm" style="height: 1.5rem;"></b-form-input>
        </span>


        <b-tooltip :target="tileID" triggers="hover">
            <i class="hoverIcon fa-fw fas fa-trash mr-1"></i>
            <i class="hoverIcon fa-fw fas fa-pen" @click="editProperty"></i>
        </b-tooltip>

        <span v-show="is_hovering && is_editing_property == false" class="floatingCornerIconOutside floatingCornerIconOutside bg-soft-info">
            <i class="hoverIcon fa-fw fas fa-trash mr-1"></i>
            <i class="hoverIcon fa-fw fas fa-pen" @click="editProperty"></i>
        </span>
        <span v-show="is_editing_property" class="floatingCornerIconOutside bg-soft-success">
            <i class="hoverIcon fa-fw fas fa-times mr-1" @click="cancelEdit"></i>
            <i class="hoverIcon fa-fw fas fa-check" @click="saveEdit"></i>
        </span>
        
    </div>
</template>

<script>
import { productMethods, productComputed } from '@/state/helpers'
import { cloneDeep } from 'lodash'
export default {
    props:{
        property:{
            required: true,
        }
    },
    data:() => ({
        is_hovering: false,
        is_editing_property: false,
        edit_value: '',
    }),
    computed:{
        ...productComputed,
        tileID(){
            return `propertyTile-${this.property.id}`
        },
        hasSavedValue(){
            return this.property.value ? '' : 'bg-soft-danger'
        }
    },
    methods:{
        ...productMethods,
        setHoverState(hover_state){
            if(this.edit_value == 'Do not pass')
            this.is_hovering = hover_state;
        },
        editProperty(){
            // create copy of value, to prevent prop mutation warning
            this.edit_value = cloneDeep(this.property.value);
            this.is_editing_property = true;
        },
        saveEdit(){
            let payload = {
                product_id: this.selected_product_id,
                property_values: [
                    {
                        property_id: this.property.id,
                        value: this.edit_value,
                    }
                ]
            }
            this.saveProductPropertyValue(payload)
            .then(()=>{
                this.$swal.fire({
                    icon:'success',
                    text: 'Updated property value',
                    title:'Saved!',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                //this.$swal.fire("Saved", "Updated property value", "success");
                this.cancelEdit();
                this.getProductDetails();
            })
        },
        cancelEdit(){
            this.edit_value = '';
            this.is_editing_property = false;
        }
    },
    mounted(){},
}
</script>

<style>
.floatingCornerIcon{
    position: absolute;
    top: 2px;
    right: 2px;
}
.floatingCornerIconOutside{
    border-left: 1px solid #eff2f7;
    border-top: 1px solid #eff2f7;
    border-right: 1px solid #eff2f7;
    border-radius: 0.1rem;
    position: absolute;
    top: -21px;
    right: 0px;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
.hoverIcon{
    color: gray;
}
.hoverIcon:hover{
    cursor: pointer;
    color:black
}
</style>