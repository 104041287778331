<template>
    <div>
        <!-- if hasprudct -->
        <simpleLayout
            v-if="hasProductSelected == false"
            :items_count="all_products.length"
        >
            <template #buttons>
                <button
                    type="button"
                    class="btn btn-success mb-2 mr-2"
                    @click="showProductModal"
                >
                    <i class="mdi mdi-plus mr-1"></i> Add Product
                </button>
                <button type="button" class="btn btn-success mb-2 mr-2" @click="exportToExcel('productsTable')"><i class="mdi mdi-file-excel mr-1"></i> Export to Excel</button>
            </template>
            <template #content>
                <productsTable @editProduct="handleProductEdit" />
            </template>
        </simpleLayout>

        <div v-if="hasProductSelected">
            <productDetails @editProduct="handleProductEdit" />
        </div>

        <productsModal v-if="show_modal" :product_id="edit_id" />
    </div>
</template>

<script>
import productsModal from "./productsModal.vue";
import productsTable from "./productsTable.vue";
import productDetails from "./productDetails.vue";
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import { productComputed, productMethods } from "@/state/helpers";
export default {
    components: {
        simpleLayout,
        productsModal,
        productsTable,
        //eslint-disable-next-line
        productDetails,
    },
    data: () => ({
        show_modal: false,
        edit_id: -1,
    }),
    computed: {
        ...productComputed,
        hasProductSelected() {
            return this.selected_product_id != -1;
        },
    },
    methods: {
        ...productMethods,
        exportToExcel(id) {
            /* eslint-disable */
            return webix.toExcel($$(id), {
                ignore: { "actions": true },
                filename: _.uniqueId("productsExport_"), // for filename
                name: "Products" // for sheet name
            });
            /* eslint-enable */            
        },
        showProductModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("productsModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (
                        event.type == "hidden" &&
                        event.componentId == "productsModal"
                    ) {
                        this.show_modal = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
        handleProductEdit(product_id) {
            this.edit_id = product_id;
            this.showProductModal();
        },
    },
    mounted() {
        if (this.all_products.length == 0) {
            this.getProducts().then(() => {
                //this.ready = true;
            });
        }
    },
};
</script>

<style></style>
